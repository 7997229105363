<template>
  <div id="mian">
    <div class="desc">
        <h5 class="jsxx">终端IP配置信息</h5>
    </div>
    <div class="input_con">
      <ul class="ul_left">
        <li>
          <span>商户名称</span>
          <div>{{ $route.query.mchName }}</div>
        </li>
        <li v-for="(v, i) in formData.productList" :key="i">
          <span><i>*</i>产品类型</span>
          <div class="inputLine">
            <el-select
              :class="{
                'inputs-select': pageType !== 'edit',
                inputs: pageType == 'edit',
              }"
              v-model="formData.productList[i].index"
              placeholder="产品类型"
              tabindex="1"
              @change="selectProduct($event, i)"
            >
              <el-option
                :label="val.productName"
                :value="idx"
                v-for="(val, idx) in payProducts"
                :key="idx + '-' + i"
                :disabled="val.disabled"
              ></el-option>
            </el-select>
            <template v-if="pageType !== 'edit'">
              <span class="add-icon" @click="addProduct" v-if="i == 0">+</span>
              <span class="add-icon" @click="delProduct(i)" v-else>-</span>
            </template>
          </div>
        </li>
      </ul>
      <ul class="ul_right">
        <li>
          <span>商户编号</span>
          <div>{{ $route.query.mchId }}</div>
        </li>
        <li>
          <span><i>*</i>地区</span>
          <div class="inputCity">
            <el-select
              class="inputs-area"
              v-model="formData.pid"
              placeholder="省份"
              tabindex="2"
              @change="cityChange"
            >
              <el-option
                :label="v.pName"
                :value="v.pid"
                v-for="v in provincial"
                :key="v.id"
              ></el-option>
            </el-select>
            <el-select
              class="inputs-area"
              v-model="formData.cid"
              placeholder="城市"
              tabindex="3"
            >
              <el-option
                :label="v.cName"
                :value="v.cid"
                v-for="v in cityList"
                :key="v.id"
              ></el-option>
            </el-select>
          </div>
        </li>
      </ul>
    </div>
    <div class="desc">
        <h5 class="jsxx">店铺配置</h5>
    </div>    
    <div class="input_con tree">
      <el-tree
        ref="tree"
        :data="stores"
        :default-checked-keys="defaultStores"
        node-key="id"
        show-checkbox
        accordion
        @check="storeChange"
        @node-click="nodeCheckClick"
        @check-change="ckeckChangeClick"
      >
      </el-tree>
    </div>
    <div class="btn">
      <el-button @click="back" class="back">取消</el-button>
      <el-button @click="onSubmit" class="next" type="primary">{{
        pageType == "edit" ? "保存" : "确认"
      }}</el-button>
    </div>
  </div>
</template>
<script>
import {
  getIpDetail,
  setIpConfig,
  getMchList,
  getIpProducts,
} from "@/api/user/ip.js";
import { getAddress } from "@/api/common.js";
export default {
  data() {
    return {
      formData: {
        storeList: [], //true
        cid: "",
        pid: "", //true
        productList: [
          {
            productId: "",
          },
        ], //true
      },
      pageType: "",
      provincial: [],
      city: [],
      cityList: [],
      payProducts: [],
      stores: [],
      defaultStores: [],
    };
  },
  created() {
    this.getDrop();
    if (this.$route.query.pageType == "edit") {
      this.pageType = "edit";
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      getIpDetail(this.$route.query.mchId, this.$route.query.id).then((res) => {
        if (res) {
          this.formData = res.resultData.mchIpConfig;
          let findIndex = this.payProducts.findIndex(
            (obj) =>
              obj.productId == res.resultData.mchIpConfig.productId &&
              obj.mchChannelId == res.resultData.mchIpConfig.mchChannelId
          );
          this.formData.productList = [
            {
              productId: res.resultData.mchIpConfig.productId,
              index: findIndex,
              mchChannelId: res.resultData.mchIpConfig.mchChannelId,
            },
          ];
          this.formData.storeList = [
            {
              storeId: res.resultData.mchIpConfig.storeId,
              tradeMchNo: res.resultData.mchIpConfig.tradeMchNo,
            },
          ];
          this.defaultStores = [
            res.resultData.mchIpConfig.storeId +
              "" +
              res.resultData.mchIpConfig.tradeMchNo,
          ];
          if (this.city.length > 0) {
            this.cityList = this.city.filter(
              (obj) => obj.pid == this.formData.pid
            );
          }
        }
      });
    },
    getDrop() {
      getAddress().then((res) => {
        this.provincial = res.resultData.provincial;
        this.city = res.resultData.city;
        if (this.pageType != "edit") {
          this.cityList = res.resultData.city;
        } else {
          if (this.formData.pid) {
            this.cityList = this.city.filter(
              (obj) => obj.pid == this.formData.pid
            );
          }
        }
      });
      getIpProducts(this.$route.query.mchId).then((res) => {
        if (res) {
          let payProducts = res.resultData.payProducts || [];
          payProducts.forEach((obj) => {
            obj.disabled = false;
          });
          this.payProducts = payProducts;
          if (this.$route.query.pageType == "edit") {
            this.pageType = "edit";
            this.getDetail();
          }
        }
      });
      getMchList(this.$route.query.mchId).then((res) => {
        if (res) {
          res.resultData.stores.forEach((obj) => {
            obj.label = `${obj.storeName}（${obj.storeId}）`;
            obj.id = obj.storeId;
            obj.disabled = this.pageType == "edit";
            obj.children = obj.storeTradeList.map((val) => {
              val.label =
                "报备商户：" +
                val.tradeMchName +
                "；报备商户号：" +
                val.tradeMchNo +
                "；备注：" +
                (val.remark || "");
              val.id = obj.storeId + "" + val.tradeMchNo;
              val.storeNo = obj.storeId;
              return val;
            });
          });
          this.stores = res.resultData.stores;
        }
      });
    },
    selectProduct(e, index) {
      this.formData.productList[index].productId =
        this.payProducts[e].productId;
      this.formData.productList[index].mchChannelId =
        this.payProducts[e].mchChannelId;
      this.payProducts.forEach((res) => {
        res.disabled = false;
        let findIndex = this.formData.productList.findIndex(
          (obj) =>
            obj.productId == res.productId &&
            obj.mchChannelId == res.mchChannelId
        );
        if (findIndex !== -1) {
          res.disabled = true;
        }
      });
    },
    addProduct() {
      this.formData.productList.push({
        productId: "",
      });
    },
    delProduct(index) {
      this.formData.productList.splice(index, 1);
      this.payProducts.forEach((res) => {
        res.disabled = false;
        let findIndex = this.formData.productList.findIndex(
          (obj) =>
            obj.productId == res.productId &&
            obj.mchChannelId == res.mchChannelId
        );
        if (findIndex !== -1) {
          res.disabled = true;
        }
      });
    },
    cityChange(e) {
      this.formData.cid = "";
      this.cityList = this.city.filter((obj) => obj.pid == e);
    },
    storeChange(e, list) {
      if (this.pageType !== "edit") {
        let value = list.checkedNodes.filter((obj) => obj.storeNo);
        this.formData.storeList = value.map((obj) => {
          return {
            storeId: obj.storeNo,
            tradeMchNo: obj.tradeMchNo,
          };
        });
      }
    },
    ckeckChangeClick(data, checked) {
      if (this.pageType == "edit") {
        if (data.tradeMchNo && data.storeNo && checked) {
          this.formData.storeList = [
            {
              storeId: data.storeNo,
              tradeMchNo: data.tradeMchNo,
            },
          ];
          this.$refs.tree.setCheckedNodes([data]);
        }
      }
    },
    nodeCheckClick(data, checked) {
      if (this.pageType == "edit") {
        if (data.tradeMchNo && data.storeNo && checked) {
          this.formData.storeList = [
            {
              storeId: data.storeNo,
              tradeMchNo: data.tradeMchNo,
            },
          ];
          this.$refs.tree.setCheckedNodes([data]);
        }
      }
    },
    back() {
      this.$router.go(-1);
    },
    // 表单规则
    roles() {
      let data = JSON.parse(JSON.stringify(this.formData));
      // console.log("aaaaaaa", data.productList);
      // if (!data.productList.length) {
      //   this.$message.error("产品必选");
      //   return false;
      // }
      let arry = data.productList;
      let tempArr = arry.filter((i) => {
        return i.productId != "";
      });
      // console.log("aaaaaaa", tempArr.length);
      if (tempArr.length < 1) {
        this.$message.error("产品类型必选");
        return false;
      }
      if (!data.pid) {
        this.$message.error("省份必选");
        return false;
      } else {
        if (!data.cid) {
          this.$message.error("城市必选");
          return false;
        }
      }
      if (!data.storeList.length) {
        this.$message.error("商户号必选");
        return false;
      }

      return true;
    },
    // 提交
    onSubmit() {
      if (!this.roles()) {
        return;
      }
      const data = {
        storeList: this.formData.storeList,
        cid: this.formData.cid,
        pid: this.formData.pid, //true
        productList: this.formData.productList, //true
      };
      if (this.pageType == "edit") {
        data.id = Number(this.$route.query.id);
      }
      setIpConfig(this.$route.query.mchId, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          if (this.$route.query.pageType == "edit") {
            this.$router.go(-1);
            return;
          }
          this.$router.push({
            name: "ip_setting",
          });
        }
      });
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}



.input_con ul li span i {
  color: #48b8b6;
  padding-right: 3px;
}

/* .ul_right {
  float: right;
  height: 100%;
  text-align: right;
  margin-right: 28px;
}

.ul_left {
  float: left;
  width: 498px;
  height: 100%;
  text-align: right;
} */


.input_con {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 4px;
    overflow: hidden;
    margin: 20px 0px;
    padding: 20px 20px 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}
.input_con  ul{
  width: 48%;
}
.input_con ul:nth-child(2n){
  margin-left: 4%;
}
.input_con  ul li{
  display: flex;
  flex-wrap: wrap;
  height: 40px;
  margin-bottom: 20px;
  line-height: 40px;
}
.input_con  ul li i {
  color: #48b8b6;
  padding-right: 3px;
}

.input_con ul li span {
  padding-right: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  width: 70px;
  display: inline-block;
  text-align: right;
}
.input_con ul li span.add-icon {
  display: inline-block;
  width: 30px;
  padding-right: 0 !important;
  margin-top: -10px;
  text-align: center;
  font-size: 24px !important;
  vertical-align: middle;
  color: #48b8b6 !important;
  cursor: pointer;
}
.input_con  ul li>div{
  width: calc(100% - 80px);
  font-size: 14px;
}
.input_con.tree {
  padding: 20px;
  box-sizing: border-box;
}
.inputs-area {
  width: calc((100% - 20px) / 2);
}
.inputs-area + .inputs-area {
  margin-left: 20px;
}
.inputs-select {
  width: calc(100% - 30px);
  min-height: 36px;
  height: auto;
  margin-bottom: 20px;
}
.inputs {
  width: 100%;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  margin-bottom: 20px;
}
.el-tree{
  width: 100%;
}
</style>